import removeHtmlTags from "./helpers/removeHtmlTags";
import truncateText from "./helpers/truncateText";

export default [
  {
    title: "Date Time",
    isTranslate: false,
    style: { minWidth: 160 },
    isShow: true,
    isDrag: true,
    key: "dateTime",
    defaultHide: true,
    defaultDragIndex: 0,
    mainIndex: 0,
    cellView: (row) =>
      `<p class="G-text-table">${
        row?.comment?.createdAt
      }</p>`,
  },
  {
    title: "Reminder Date",
    isTranslate: false,
    style: { minWidth: 160 },
    isShow: true,
    isDrag: true,
    key: "reminderDate",
    defaultHide: true,
    defaultDragIndex: 0,
    mainIndex: 0,
    cellView: (row) =>
      `<p class="G-text-table">
    ${row?.comment?.reminderDate}</p>`,
  },
  {
    title: "Client Name",
    isTranslate: false,
    style: { minWidth: 160 },
    isShow: true,
    isDrag: true,
    key: "clientName",
    defaultHide: true,
    defaultDragIndex: 0,
    mainIndex: 0,
    cellView: (row) =>
      `<p class="G-text-table">${
        row?.client?.personalInfo?.firstname 
      }${row?.client?.personalInfo?.lastname}</p>`,
  },
  {
    title: "Provider",
    isTranslate: false,
    style: { minWidth: 160 },
    isShow: true,
    isDrag: true,
    key: "provider",
    defaultHide: true,
    defaultDragIndex: 0,
    mainIndex: 0,
    cellView: (row) =>
      `<p class="G-text-table">${
        row?.provider?.name?.[0]?.name
      }</p>`,
  },
  {
    title: "Comment",
    isTranslate: false,
    style: { minWidth: 160 },
    isShow: true,
    isDrag: true,
    key: "comment",
    defaultHide: true,
    defaultDragIndex: 0,
    isColumnClicked: true,
    mainIndex: 0,
    cellView: (row) => `
    <div>
      <p>${truncateText(removeHtmlTags(row?.comment?.comment ?? ''), 3)}</p>
      <button class="G-text-table"> View More </button>
    </div>
    `
  },
  {
    title: "By User",
    isTranslate: false,
    style: { minWidth: 160 },
    isShow: true,
    isDrag: true,
    key: "byUser",
    defaultHide: true,
    defaultDragIndex: 0,
    mainIndex: 0,
    cellView: (row) => {
      return `${row?.user?.firstname ?? '-'} ${row?.user?.lastname ?? '-'}`;
    }
  },
  {
    title: "Notification Reader",
    isTranslate: false,
    style: { minWidth: 160 },
    isShow: true,
    isDrag: true,
    key: "notificationReader",
    defaultHide: true,
    defaultDragIndex: 0,
    mainIndex: 0,
    cellView: (row) => `${row?.updateUser?.firstname ?? '-'} ${row?.updateUser?.lastname ?? '-'}`
  },
  {
    title: "Is Private",
    isTranslate: false,
    style: { minWidth: 160 },
    isShow: true,
    isDrag: true,
    key: "isPrivate",
    defaultHide: true,
    defaultDragIndex: 0,
    mainIndex: 0,
    cellView: (row) =>
      `<p class="G-text-table">${
        row?.isPrivate
      }</p>`,
  },
];
