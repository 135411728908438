import Vue from "vue";
import Vuex from "vuex";
import { general } from './general/general';
import { auth } from "./auth/auth.module";
import { registration } from "./auth/registration.module";
import { forgotPassword } from "./auth/forgotPassword.module";
import { sidebar } from "./sidebar/sidebar.module";
import { review } from "./review/review.module";

import { generalSearch } from "./generalSearch/generalSearch.module";

import { providers } from "./providers/providers.module";
import { addProviderModal } from "./addProviderModal/addProvider.module";

import { contactModule } from "@/store/addProviderModal/contact.module";
import { adminPersonalInfoModule } from "@/store/addProviderModal/adminPersonalInfo.module";
import { companyInfo } from "@/store/addProviderModal/companyInfo.module";
import { balanceCredit } from "./addProviderModal/balanceCredit.module";
import { companyAddresses } from "./addProviderModal/companyAddresses.module";
import { uiAndAccess } from "@/store/addProviderModal/uiAndAccess.module";
import { contactInfoForClientsApps } from "@/store/addProviderModal/contactInfoForClientsApps.module";
import { checkeeper } from "@/store/addProviderModal/checkeeper.module";
import { paymentMethods } from "./addProviderModal/paymentMethods.module";

import { otherApiIntegration } from "./addProviderModal/otherApiIntegration.module";
import { merchant } from "./addProviderModal/merchant.module";
import { shipmentProviders } from "./addProviderModal/shippingProviders.module";
import { labelPrinters } from "./addProviderModal/labelPrinters.module";
import { permissions } from "./addProviderModal/permissions.module";
import { saleTaxes } from "./addProviderModal/saleTaxes.module";
import { invoice } from "./addProviderModal/invoice.module";
import { users } from "./users/users";
import { uploadImageModal } from "./uploadImageMidal/uploadImageModal";
import { generalInfoUser } from "./users/generalInfoUser";
import { roleUser } from "./users/role";
import { botUserModule } from "./users/bot.module"
import { securityLogin } from "./users/securityLogin";
import { fileUpload } from "./fileUpload/fileUpload";
import { clientsModule } from "./clients/clients.module";
import { clientsDeletedModule } from "./clientsDeleted/clientsDeleted.module";
import { clientActivityModule } from "./clientActivity/clientActivity";
import { personalInfoClient } from "./clients/personalInfo.module";
import { comments } from "./clients/comments.module";
import { contactClient } from "./clients/contact.module";
import { addressClient } from "./clients/address.module";
import { paymentMethodClients } from "./clients/paymentMethodsClients.module";
import { financeClient } from "./clients/finance.module";
import { balanceCreditClient } from "./clients/balanceCreditClient.module";
import { locationClient } from "./clients/location.module";
import { usedDevicesClient } from "./clients/usedDevices";
import { profileClients } from "./clients/profile.module";
import { packagesClients } from "./clients/packages.module";
import { equipments } from "./clients/equipments.module";
import { checkoutClients } from "./clients/checkoutClients";
import { shippingClients } from "./clients/shippingClients";
import { manageAdminModule } from "./manageAdmin/manageAdmin.module";
import { companyInfoAdminModule } from "./manageAdmin/companyInfoAdmin.module";
import { contactAdminModule } from "./manageAdmin/contactAdmin.module";
import { personalInfoAdminModule } from "./manageAdmin/personalInfoAdmin.module";
import { companyAddressesAdmin } from "./manageAdmin/companyAddressesAdmin.module";
import { paymentMethodsAdmin } from "./manageAdmin/paymentMethodsAdmin.module";
import { paymentGatewayAdmin } from "./manageAdmin/paymentGatewayAdmin.module";
import { shippingProvidersAdmin } from "./manageAdmin/shippingProvidersAdmin.module";
import { labelPrintersAdmin } from "./manageAdmin/labelPrintersAdmin.module";
import { uiAndAccessAdmin } from "./manageAdmin/uiAndAccessAdmin.module";
import { infoForClientsAppsAdmin } from "./manageAdmin/infoForClientsApps.module";
import { saleTaxesAdmin } from "./manageAdmin/saleTaxesAdmin.module";
import { invoiceAdmin } from "./manageAdmin/invoiceAdmin.module";
import { otherApiIntegrationAdmin } from "./manageAdmin/otherApiIntegrationAdmin.module";
import { priceGroupAdmin } from "./manageAdmin/priceGroupAdmin.module";
import { conversationApiModule } from "./manageAdmin/conversationApi.module";
import { packagesModule } from "./packages/packages.module";
import { packagesGeneralInfo } from "./packages/generalInfo.module";

import { balanceRefill } from "./sidebar/balanceRefill.module";
import { discountModule } from "./discount/discount.module";
import { generalInfoDiscount } from "./discount/generalInfoDiscount.module";
import { priceGroupDiscount } from "./discount/priceGroupDiscount.module";
import { packagesDiscount } from "./discount/packagesDiscount.module";
import { notificationDiscount } from "./discount/notoficationsDiscount.module";

import { clientBillsModule } from "./clientPayments/client-bills.module";
import { transactionsModule } from "./transactions/transactions.module";
import { usersActivityModule } from "./UsersActivity/usersActivity.module";
import { appModule } from './app/app.module'

import { equipmentsModule } from './equpments/equipments.module'
import { equipmentsTypesModule } from './equpments/equipmentsTypes.module'

import { flussonicsModule } from './flussonics/flussonics.module'
import { generalFlussonic } from './flussonics/general.module'
import { portsFlussonic } from './flussonics/ports.module'
import { dvrVodFlussonic } from './flussonics/dvrVod.module'

import { notifications } from './notifications/notifications'
import { notificationsListModule } from "./notificationsList/notificationsList.module";
import { fullCalendar } from './fullCalendar/fullCalendar'
import { shippingsModule } from './shippings/shippings.module'

import { securePaymentsModule } from './payment/payment.module'
import { chatModule } from './chat/chat.module'
import { mainChatModule } from './chat/main-chat.module'
import { statisticModule } from './statistic/statistic.module'

import { help } from './help/help'

Vue.use(Vuex);

const state = {
  sidebarShow: "responsive",
  sidebarMinimize: false,
  asideShow: false,
  darkMode: false,
  route: null
  // languageStore: new LanguageStore(),
};
const actions = {};
const mutations = {
  setRoute(state, route) {
    state.route = route;
  }
};
const store = new Vuex.Store({
  state,
  actions,
  mutations,
  modules: {
    general,
    auth,
    registration,
    forgotPassword,
    sidebar,
    review,
    notificationsListModule,
    //generalSearch
    generalSearch,

    // Providers Store
    providers,
    addProviderModal,
    contactModule,
    adminPersonalInfoModule,
    companyInfo,
    companyAddresses,
    balanceCredit,
    uiAndAccess,
    contactInfoForClientsApps,
    checkeeper,
    paymentMethods,
    saleTaxes,
    otherApiIntegration,
    merchant,
    shipmentProviders,
    labelPrinters,
    permissions,
    invoice,

    //  Users Store
    users,

    // uploadImageModal

    uploadImageModal,
    generalInfoUser,
    roleUser,
    securityLogin,
    botUserModule,

    // file upload
    fileUpload,

    // clientsModule
    clientsModule,
    clientsDeletedModule,
    clientActivityModule,
    personalInfoClient,
    comments,
    contactClient,
    addressClient,
    paymentMethodClients,
    financeClient,
    balanceCreditClient,
    locationClient,
    usedDevicesClient,
    profileClients,
    packagesClients,
    equipments,

    // manage admin module
    manageAdminModule,
    companyInfoAdminModule,
    contactAdminModule,
    personalInfoAdminModule,
    checkoutClients,
    shippingClients,
    companyAddressesAdmin,
    paymentMethodsAdmin,
    paymentGatewayAdmin,
    shippingProvidersAdmin,
    labelPrintersAdmin,
    uiAndAccessAdmin,
    infoForClientsAppsAdmin,
    saleTaxesAdmin,
    invoiceAdmin,
    otherApiIntegrationAdmin,
    priceGroupAdmin,
    // balance refill(refund) modal
    balanceRefill,
    conversationApiModule,

    // packages
    packagesModule,
    packagesGeneralInfo,

    // discount
    discountModule,
    generalInfoDiscount,
    priceGroupDiscount,
    packagesDiscount,
    notificationDiscount,

    //  Client Payments
    clientBillsModule,

    // transactions
    transactionsModule,
    appModule,

    // Client Activity
    usersActivityModule,

    // Equipments
    equipmentsModule,
    equipmentsTypesModule,

    // Flussonics
    flussonicsModule,
    generalFlussonic,
    portsFlussonic,
    dvrVodFlussonic,

    notifications,
    fullCalendar,
    shippingsModule,

    // Payments
    securePaymentsModule,

    // Chat
    chatModule,
    mainChatModule,

    help,
    // statistic module
    statisticModule,
  }
});

export default store;
