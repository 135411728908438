<template>
  <div class="P-users-list">
    <div class="G-page-component">
      <!-- <div class="G-page-title-block G-flex G-justify-between G-align-center">
        <div class="G-component-title">
          <h3 class="secondary--text">Notifications</h3>
        </div>
      </div> -->
      <CommentModal />
      <!-- <div class="G-component-header page-background">
        <div class="input--wrapper">
          <OttInput
            ref="searchInput"
            v-model="searchValueW"
            :timeoutInput="true"
            @timeoutInput="search"
            :value="searchValueW"
            :label="'Search...'"
          >
            <template v-slot:error-message>
              <span>Type something...</span>
            </template>
          </OttInput>
        </div>
      </div> -->
      <div class="G-component-body page-background">
        <template v-if="notificationsListModule.dataTable.length > 0 && !notificationsListModule.isLoading">
          <div class="G-sub-block-title">
            <h3 class="secondary--text">Notifications List</h3>
          </div>

          <OttUniversalTable
            :main-data="notificationsListModule.dataTable"
            :column-config="columnConfigData"
            :selected-arrow-list="selectedArrowList"
            :data="notificationsListModule.dataTable"
            :is-sub-table="false"
            :is-multi-select="false"
            :fixed-first-and-last-column="true"
            :is-total-sum="false"
            @selectRow="() => {}"
            @sortData="() => {}"
            @updateColumns="() => {}"
            @clickColumnData="openCommentModal"
            @updateColumnWith="() => {}"
            @saveColumnChangesFromDrag="() => {}"
          >
          </OttUniversalTable>

          <OttPaginationForTable
            :data-count="notificationsListModule.dataCount"
            :available-data-count="notificationsListModule.dataTable.length"
            :current-page="notificationsListModule.currentPage"
            :page-limit="notificationsListModule.totalPages"
            :value="notificationsListModule.rowsPerPageCount"
            @input="updateRowsPerPageCount"
            @update="() => {}"
            @updatePage="changePage"
          />
        </template>
        <NoDataInTable v-else />
      </div>
    </div>

    <PageLoader v-if="notificationsListModule.isLoading" />
  </div>
</template>
<script>
//mixins
import FilterMixin from "@/mixins/FilterMixin";
import NotificationsMixin from "./NotificationsMixin";
import DateFormatMixin from "@/mixins/DateFormatMixin";
import ColumnConfigMixin from "@/mixins/ColumnConfigMixin";
import OttUniversalTableForComponentsMixin from "@/components/OttUniversalTable/OttUniversalTableForComponentsMixin";

//components
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttButton from "@/components/vuetifyComponents/OttButton";
import OttSwitch from "@/components/vuetifyComponents/OttSwitch";
import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
import OttRadio from "../../components/vuetifyComponents/OttRadio";
import OttSelect from "../../components/vuetifyComponents/OttSelect";
import NoDataInTable from "@/components/NoDataInTable";
import PageLoader from "@/components/pageLoader/PageLoader";
import OttUniversalTable from "@/components/OttUniversalTable/OttUniversalTable";
import OttPaginationForTable from "@/components/OttUniversalTable/OttPaginationForTable";
import CommentModal from "./components/CommentModal";

export default {
  name: "Client",
  components: {
    OttSelect,
    OttInput,
    OttRadio,
    OttButton,
    OttSwitch,
    PageLoader,
    OttCheckbox,
    NoDataInTable,
    OttUniversalTable,
    OttPaginationForTable,
    CommentModal,
  },
  mixins: [
    FilterMixin,
    DateFormatMixin,
    ColumnConfigMixin,
    NotificationsMixin,
    OttUniversalTableForComponentsMixin,
  ],
  watch: {
    "notificationsListModule.isLoading"(isLoading) {
      if (!isLoading) {
        this.$nextTick(() => {
          const searchInputEl =
            this.$refs.searchInput.$el.querySelector("input");
          if (searchInputEl) {
            searchInputEl.focus();
          }
        });
      }
    },
  },
  // updated() {

  // },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/variables";

.deleted--header {
  display: flex;
  justify-content: space-between;
}

.P-users-columns {
  padding-left: 10px;
}

::v-deep {
  .v-text-field__details {
    display: none;
  }
  .v-input--dense > .v-input__control > .v-input__slot {
    margin-bottom: 0;
  }
}
.P-radio-box {
  margin-right: 15px;
}

::v-deep {
  .v-input__slot {
    margin-bottom: 0;
  }

  .G-paused-badge-time {
    width: max-content;
    padding: 0 10px;
  }
}
</style>
