const Notifications = () => import("@/views/notifications/Notifications");

export const notificationsRoute = {
    path: "/notifications",
    redirect: "/notifications",
    name: "Notifications",
    component: Notifications,
    children: [
      {
        path: "",
        name: "",
        component: Notifications,
      }
    ]
  };
  