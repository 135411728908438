import { mapActions, mapMutations, mapState } from "vuex";
import columns from "./columns";

export default {
  data() {
    return {
      defaultColumnConfigData: [],
      columnConfigData: [],
      defaultSettings: [], // don't remove this, it's need for createColumnConfigData
    };
  },
  computed: {
    ...mapState({
      locationSyncState: (state) => state.appModule.locationSyncState,
      notificationsListModule: (state) => state.notificationsListModule,
      extendedType: (state) => state.notificationsListModule.extendedType,
      dataTable: (state) => state.notificationsListModule.dataTable,
      filter: (state) => state.notificationsListModule.filter,
    }),

    defaultColumnConfigs() {
      return columns;
    },

    searchValueW: {
      set(value) {
        this.setSearchValue(value);
      },
      get() {
        return this.notificationsListModule.searchValue;
      },
    },
  },

  methods: {
    ...mapActions({
      getNotificationsList: "notificationsListModule/getNotificationsList",
      search: "notificationsListModule/search",
    }),
    ...mapMutations({
      setCurrentPage: "notificationsListModule/setCurrentPage",
      setRowsPerPageCount: "notificationsListModule/setRowsPerPageCount",
      setSearchValue: "notificationsListModule/setSearchValue",
      resetState: "notificationsListModule/resetState",
      setIsOpenCommentModal: 'notificationsListModule/setIsOpenCommentModal',
      setClickedRow: 'notificationsListModule/setClickedRow'
    }),

    hideShowColumns() {
      this.columnConfigData.map((item) => {
        if (item.key === "recurringDate") {
          item.isShow = false;
          item.customUnshow = true;
          item.disabled = true;
        }
      });
    },

    async updateRowsPerPageCount(count) {
      this.setRowsPerPageCount(count);
      await this.getNotificationsList();
      this.resetSelectedList();
    },

    async resetDataTableSettings() {
      this.createColumnConfigData();
      this.hideShowColumns();
    },

    async changePage(page) {
      if (page) {
        this.setCurrentPage(page);
      }
      await this.getNotificationsList();
      this.resetSelectedList();
    },

    async openCommentModal(row) {
      this.setIsOpenCommentModal(true);
      this.setClickedRow(row);
    },

  },

  async created() {
    this.createColumnConfigData();

    try {
      await this.getNotificationsList();
      this.resetSelectedList();
    } catch (err) {
      console.error(err);
    }
  },

  beforeDestroy() {},

  destroyed() {
    this.resetState(this.notificationsListModule);
  },
};
