<template>
  <ott-dialog
    v-if="notificationsListModule.isOpenCommentModal"
    :is-open="notificationsListModule.isOpenCommentModal"
    :show-footer="false"
    :show-header="false"
    show-transparent-header
    @outside="closeModal"
    @resetDataModal="closeModal"
  >
    <template #modalBody>
      <div class="comment-modal">
        <div class="comment-modal__body">
         {{ removeHtmlTags(notificationsListModule.clickedRow.comment.comment) }}
        </div>
        <div class="G-flex justify-space-between buttons-block">
          <ott-button
            :click="closeModal"
            class="primary-sm-btn"
            text="Close"
          />
        </div>
      </div>
    </template>
  </ott-dialog>
</template>

<script>
  import {mapActions, mapMutations, mapState} from "vuex";
  // components
  import OttDialog from "@/components/vuetifyComponents/OttDialog";
  import OttButton from "@/components/vuetifyComponents/OttButton";
  import removeHtmlTags from "../helpers/removeHtmlTags";

  export default {
    name: "CommentModal",
    components: {OttButton, OttDialog},
    computed: {
      ...mapState({
        notificationsListModule: (state) => state.notificationsListModule,
      })
    },
    methods: {
      ...mapActions({
      }),
      ...mapMutations({
        setIsOpenCommentModal: 'notificationsListModule/setIsOpenCommentModal',
      }),
      closeModal() {
        this.setIsOpenCommentModal(false)
      },
      removeHtmlTags
    },
  }
</script>

<style lang="scss" scoped>
  @import "./src/assets/scss/variables";

  .comment-modal {
    &__body {
      padding : 24px 24px 24px 20px;
    }

    &__title {
      font-size     : $txt16;
      font-weight   : $semiBold;
      color         : $secondary-color;
      margin-bottom : 15px;
      margin-left   : 4px;
    }

    .mb-24 {
      margin-bottom : 24px;
    }

    .buttons-block {
      border-top : 1px solid $neutral-color;
      padding    : 24px;
    }
  }
</style>
